import React, { useEffect, useMemo, useState } from 'react';

import {
  InfoCircleOutlined,
  LockFilled,
  ToolOutlined,
} from '@ant-design/icons';
import { createSelector } from '@reduxjs/toolkit';
import { Col, Row, Select, Space, Tooltip } from 'antd';
import {
  TAssertionMapToParsed,
  TCollapseContainer,
  TComponent,
  TConditionalOperands,
  TConditionalValues,
  TRepeatingList,
  TTemplateCapability,
  TTestObject,
  TTestObjectOperator,
} from 'src/api/types/paywallTemplate.types';
import { useAppContext } from 'src/hooks';
import { useEntitlementRefIdMap } from 'src/hooks/queries/entitlement.hooks';
import { useAppSelector } from 'src/hooks/redux.hooks';
import { RootState } from 'src/redux';
import {
  deconstructVariable,
  getCustomVariableFromLaunchContext,
  parseConditionRuleToTestObject,
  parseOperatorFromCustomLaunchObject,
} from 'src/utils/paywall';
import { namiBrightBlue } from 'src/variables';

import { TConditionEditorType } from '../../../utils/componentGeneration';
import { UpgradeButton } from './FontSelect';

type ConditionRuleEditorProps = {
  condition: TTestObject;
  templateCapabilities: TTemplateCapability[];
  openCapabilitiesTab: () => void;
  updateCondition: (
    assertion: TTestObject,
    id: string,
    error?: boolean
  ) => void;
  id: string;
  productChild: boolean;
  childOfCollapseComponent?: TCollapseContainer;
  parentRepeatingGrid: TComponent | null;
  onClose: () => void;
  variant: TConditionEditorType;
};

//Hard coded operands. Doesn't include values for product groups
const ValuesByOperand: Record<
  TConditionalOperands,
  Array<{ label: string; value: TConditionalValues }>
> = {
  sku: [
    {
      value: 'featured',
      label: 'Featured',
    },
    {
      label: 'Selected',
      value: 'selected',
    },
    {
      value: 'freeTrialEligible',
      label: 'Free Trial Eligible',
    },
    {
      value: 'introEligible',
      label: 'Intro Offer Eligible',
    },
    {
      value: 'promoEligible',
      label: 'Promo Offer Eligible',
    },
  ],
  skuEntitlement: [],
  skuVariable: [],
  state: [
    // {
    //   value: 'fullScreenPresentation',
    //   label: 'Fullscreen Mode',
    // },
    {
      label: 'Notched',
      value: 'hasNotch',
    },
  ],
  user: [
    {
      label: 'Logged In',
      value: 'isLoggedIn',
    },
    {
      value: 'freeTrialEligible',
      label: 'Free Trial Eligible',
    },
    {
      value: 'introEligible',
      label: 'Intro Offer Eligible',
    },
    {
      value: 'promoEligible',
      label: 'Promo Offer Eligible',
    },
  ],
  group: [],
  launchGroup: [],
  launchContext: [],
  launchObject: [],
  collapse: [],
  appSuppliedVideo: [
    {
      label: 'set',
      value: 'true',
    },
  ],
  platform: [
    {
      label: 'Apple',
      value: 'apple',
    },
    {
      label: 'Google',
      value: 'google',
    },
    {
      label: 'Amazon',
      value: 'amazon_iap',
    },
    {
      label: 'Roku',
      value: 'roku_pay',
    },
    {
      label: 'Web',
      value: 'web',
    },
  ],
  flow: [
    {
      label: 'this Paywall',
      value: 'exists',
    },
    {
      label: 'Previous Step',
      value: 'previousStepAvailable',
    },
    {
      value: 'nextStepAvailable',
      label: 'Next Step',
    },
  ],
};
const selector = createSelector(
  [
    ({ paywallBuilder: { productGroups } }: RootState) => productGroups,
    ({ paywallBuilder: { paywall } }: RootState) =>
      paywall?.template['ui.displaySingleGroup'],
    ({ paywallBuilder: { paywall } }: RootState) =>
      paywall?.template['ui.launchCustomAttributes'],
    ({ paywallBuilder: { customObjectSchema } }: RootState) =>
      customObjectSchema,
    ({ paywallBuilder: { paywall } }: RootState) =>
      paywall!.template['ui.productSettings']?.variablesList,
    ({ paywallBuilder: { formFactor } }: RootState) => formFactor,
    ({ paywallBuilder: { paywall } }: RootState) =>
      paywall?.template['ui.formFactors'],
  ],
  (
    productGroups,
    displaySingleGroup,
    launchCustomAttributes,
    customObjectSchema,
    productVariables,
    formFactor,
    formFactors
  ) => ({
    currentProductGroups: productGroups,
    displaySingleGroup,
    launchCustomAttributes: launchCustomAttributes || {},
    customObjectSchema: customObjectSchema || [],
    currentProductVariables: productVariables || [],
    formFactor,
    rotateable:
      formFactors?.[0]?.supports_landscape &&
      formFactors?.[0]?.supports_portrait,
  })
);

export default function ConditionRuleEditor({
  condition,
  templateCapabilities,
  openCapabilitiesTab,
  updateCondition,
  id,
  productChild,
  childOfCollapseComponent,
  parentRepeatingGrid,
  onClose,
  variant,
}: ConditionRuleEditorProps) {
  const {
    currentProductGroups,
    displaySingleGroup,
    launchCustomAttributes,
    customObjectSchema,
    currentProductVariables,
    formFactor,
    rotateable,
  } = useAppSelector(selector);
  const entitlementIdMap = useEntitlementRefIdMap();
  const appContext = useAppContext();
  const manualOperands: TConditionalOperands[] = [
    'launchContext',
    'launchObject',
    'skuVariable',
  ];

  const parentDataSourceRoot = parentRepeatingGrid
    ? deconstructVariable(
        ((parentRepeatingGrid as TRepeatingList).loopSource as string) || '',
        false,
        true
      )
    : null;

  const [selectedOperand, setSelectedOperand] =
    useState<TConditionalOperands | null>(null);
  const [selectedOperandIsManual, setSelectedOperandIsManual] = useState(false);
  const [selectedOperator, setSelectedOperator] =
    useState<TTestObjectOperator>('equals');
  const [selectedValue, setSelectedValue] = useState<any | null>(null);

  const operandValues: Array<{ label: string; value: any }> = useMemo(() => {
    if (!selectedOperand) return [];
    if (selectedOperand === 'group' || selectedOperand === 'launchGroup') {
      return currentProductGroups.reduce((output, group, index) => {
        return [
          ...output,
          {
            label: group.display_name,
            value: index === 0 ? 'group0' : 'group1',
          } as { label: string; value: TConditionalValues },
        ];
      }, [] as Array<{ label: string; value: TConditionalValues }>);
    } else if (selectedOperand === 'launchContext') {
      return Object.keys(launchCustomAttributes).reduce((output, attr) => {
        return [
          ...output,
          {
            label: attr,
            value: attr,
          } as { label: string; value: TConditionalValues },
        ];
      }, [] as Array<{ label: string; value: TConditionalValues }>);
    } else if (selectedOperand === 'launchObject') {
      return customObjectSchema.reduce((output, attr) => {
        if (variant === 'loopSource') {
          if (attr.type !== 'array' && attr.child && attr.parent) {
            return [
              ...output,
              {
                label: attr.title.replace(`${attr.parent}.`, ' '),
                value: (attr.title.replace(`${attr.parent}.`, '${block.') +
                  '}') as string, //TODO-use loop variable
              } as { label: string; value: TConditionalValues },
            ];
          }
        } else if (
          parentRepeatingGrid &&
          attr.type !== 'array' &&
          attr.title.includes(parentDataSourceRoot || '')
        ) {
          return [
            ...output,
            {
              label: attr.title.replace(`${parentDataSourceRoot}.`, ' '),
              value: (attr.title.replace(
                `${parentDataSourceRoot}.`,
                '${block.'
              ) + '}') as string, //TODO-use loop variable
            } as { label: string; value: TConditionalValues },
          ];
        } else if (!parentRepeatingGrid && !attr.child) {
          return [
            ...output,
            {
              label: attr.title.replace('customObject.', ''),
              value: `\${launch.${attr.title}}` as string,
            } as { label: string; value: TConditionalValues },
          ];
        }
        return output;
      }, [] as Array<{ label: string; value: TConditionalValues }>);
    } else if (selectedOperand === 'skuEntitlement') {
      return Object.keys(entitlementIdMap).reduce((output, id) => {
        return [
          ...output,
          {
            label: entitlementIdMap[id].name,
            value: id,
          } as { label: string; value: TConditionalValues },
        ];
      }, [] as Array<{ label: string; value: TConditionalValues }>);
    } else if (selectedOperand === 'skuVariable') {
      return currentProductVariables.reduce((output, variable) => {
        return [
          ...output,
          {
            label: variable.display_name,
            value: '${sku.' + variable.variable + '}',
          } as { label: string; value: TConditionalValues },
        ];
      }, [] as Array<{ label: string; value: TConditionalValues }>);
    } else if (selectedOperand === 'collapse') {
      return [
        {
          value: 'true',
          label: 'True',
        },
      ];
    } else if (selectedOperand === 'state') {
      if (formFactor === 'phone') {
        return [
          {
            label: 'Notched',
            value: 'hasNotch',
          },
        ];
      } else if (formFactor === 'tablet') {
        if (rotateable) {
          return [
            {
              label: 'Portrait Orientation',
              value: 'portrait',
            },
            {
              label: 'Landscape Orientation',
              value: 'landscape',
            },
          ];
        }
        return [];
      } else {
        return [];
      }
    } else {
      return ValuesByOperand[selectedOperand];
    }
  }, [
    selectedOperand,
    entitlementIdMap,
    currentProductGroups,
    launchCustomAttributes,
    customObjectSchema,
    parentDataSourceRoot,
    parentRepeatingGrid,
    currentProductVariables,
    variant,
    formFactor,
    rotateable,
  ]);

  useEffect(() => {
    if (!condition) return;
    if (!(condition.operator && condition.value)) return;
    const parsedCondition = `${condition.value} ${condition.operator} ${condition.expected}`;
    if (TAssertionMapToParsed.hasOwnProperty(parsedCondition)) {
      const parsed = TAssertionMapToParsed[parsedCondition];
      setSelectedOperand(parsed.operand);
      setSelectedOperator(parsed.operator);
      setSelectedValue(parsed.value);
    } else if (condition.value.includes('launch.customAttributes')) {
      //Handle custom launch context
      const customVariable = getCustomVariableFromLaunchContext(
        condition.value
      );
      if (customVariable) {
        setSelectedOperandIsManual(true);
        setSelectedOperand('launchContext');
        setSelectedOperator(condition.operator); //TODO
        setSelectedValue(customVariable);
      }
    } else if (
      condition.value.includes('launch.customObject') ||
      condition.value.includes('block')
    ) {
      setSelectedOperandIsManual(true);
      const newOperator = parseOperatorFromCustomLaunchObject(
        condition.operator,
        condition.expected
      );
      setSelectedOperand('launchObject');
      setSelectedOperator(newOperator);
      setSelectedValue(condition.value);
    } else if (condition.value.includes('entitlements')) {
      if (!condition.expected) return;
      setSelectedOperand('skuEntitlement');
      if (['contains', 'notContains'].includes(condition.operator)) {
        setSelectedOperator(condition.operator);
      } else {
        setSelectedOperator('contains');
      }
      setSelectedValue(condition.expected);
    } else if (condition.value.includes('sku')) {
      setSelectedOperandIsManual(true);
      const newOperator = parseOperatorFromCustomLaunchObject(
        condition.operator,
        condition.expected
      );
      setSelectedOperand('skuVariable');
      setSelectedOperator(newOperator);
      setSelectedValue(condition.value);
    } else if (condition.value.includes('state.openHeaderIds')) {
      if (condition.value === null) return;
      setSelectedOperand('collapse');
      if (['equals', 'notEquals'].includes(condition.operator)) {
        setSelectedOperator(condition.operator);
      } else {
        setSelectedOperator('equals');
      }
      setSelectedValue('true');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const testObject = parseConditionRuleToTestObject(
      selectedOperand,
      selectedOperator,
      selectedValue,
      {
        collapseParentId: childOfCollapseComponent
          ? childOfCollapseComponent.id!
          : '',
      }
    );
    updateCondition(testObject.result, id, testObject.error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOperand, selectedOperator, selectedValue, id]);

  useEffect(() => {
    if (!selectedOperand) return;
    const valueOpts = operandValues.reduce((output, opt) => {
      const optStatus = checkForOperandValue(selectedOperand, opt.value);
      return [
        ...output,
        {
          value: opt.value,
          label: (
            <Space direction="horizontal" onClick={optStatus.onClick}>
              <span>{opt.label}</span>
              {optStatus.status === 'disabled' && (
                <Tooltip
                  title={optStatus.message}
                  placement="topRight"
                  align={{ offset: [15, 3] }}
                  overlayInnerStyle={{ width: 275 }}
                >
                  <InfoCircleOutlined />
                </Tooltip>
              )}
              {optStatus.status === 'locked' && (
                <LockFilled style={{ color: namiBrightBlue }} />
              )}
            </Space>
          ),
          disabled: optStatus.status !== 'available',
        },
      ];
    }, [] as Array<{ label: JSX.Element; value: TConditionalValues }>);
    setValueOptions(valueOpts);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOperand]);

  const [valueOptions, setValueOptions] = useState<
    Array<{ label: JSX.Element; value: string }>
  >([]);

  const operands: Array<{ label: string; value: TConditionalOperands }> =
    useMemo(() => {
      if (variant === 'loopSource') {
        return [
          {
            label: 'Custom Data',
            value: 'launchObject',
          },
        ];
      }

      let base: Array<{ label: string; value: TConditionalOperands }> = [
        {
          label: 'User',
          value: 'user',
        },
        {
          label: 'Platform Type',
          value: 'platform',
        },
      ];
      if (formFactor === 'phone' || (formFactor === 'tablet' && rotateable)) {
        base.unshift({
          label: 'Device',
          value: 'state',
        });
      }
      if (
        productChild &&
        appContext.userHasEntitlement(
          'app.paywall.capabilities.product_entitlement_conditionals'
        )
      )
        base.unshift({
          label: 'Product Entitlements',
          value: 'skuEntitlement',
        });
      if (productChild) {
        base.unshift({
          label: 'Product',
          value: 'sku',
        });
        base.unshift({
          label: 'Product Variable',
          value: 'skuVariable',
        });
      }
      if (templateCapabilities.includes('product_groups')) {
        if (displaySingleGroup) {
          base.push({
            label: 'Product Group',
            value: 'group',
          });
        }
        if (templateCapabilities.includes('conditional_product_groups')) {
          base.push({
            label: 'Launch Group',
            value: 'launchGroup',
          });
        }
      }
      if (templateCapabilities.includes('custom_launch_context')) {
        base.push({
          label: 'Custom Variable',
          value: 'launchContext',
        });
      }
      if (
        templateCapabilities.includes('custom_launch_object') &&
        parentRepeatingGrid
      ) {
        base.push({
          label: 'Current Cell',
          value: 'launchObject',
        });
      }
      if (
        templateCapabilities.includes('custom_launch_object') &&
        !parentRepeatingGrid
      ) {
        base.push({
          label: 'Custom Data',
          value: 'launchObject',
        });
      }
      if (childOfCollapseComponent) {
        base.push({
          label: 'Collapse Open',
          value: 'collapse',
        });
      }
      if (templateCapabilities.includes('video')) {
        base.push({
          label: 'App Supplied Video',
          value: 'appSuppliedVideo',
        });
      }
      if (templateCapabilities.includes('flow')) {
        base.push({
          label: 'Flow',
          value: 'flow',
        });
      }
      return base;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      displaySingleGroup,
      productChild,
      childOfCollapseComponent,
      templateCapabilities,
    ]);

  const operators: Array<{ label: string; value: TTestObjectOperator }> =
    useMemo(() => {
      if (selectedOperand === 'launchContext') {
        return [
          {
            label: 'is set',
            value: 'set',
          },
          {
            label: 'is not set',
            value: 'notSet',
          },
        ];
      } else if (
        selectedOperand === 'launchObject' ||
        selectedOperand === 'skuVariable'
      ) {
        return [
          {
            label: 'is set',
            value: 'set',
          },
          {
            label: 'is not set',
            value: 'notSet',
          },
          {
            label: 'is true',
            value: 'true',
          },
          {
            label: 'is false',
            value: 'false',
          },
        ];
      } else if (selectedOperand === 'skuEntitlement') {
        return [
          {
            label: 'contains',
            value: 'contains',
          },
          {
            label: 'does not contain',
            value: 'notContains',
          },
        ];
      } else if (selectedOperand === 'collapse') {
        return [
          {
            label: 'is',
            value: 'equals',
          },
        ];
      } else if (selectedOperand === 'flow') {
        return [
          {
            label: 'has',
            value: 'equals',
          },
          {
            label: "doesn't have",
            value: 'notEquals',
          },
        ];
      }
      return [
        {
          label: 'is',
          value: 'equals',
        },
        {
          label: 'is not',
          value: 'notEquals',
        },
      ];
    }, [selectedOperand]);

  return (
    <Row gutter={[10, 5]} style={{ width: '100%' }}>
      <Col span={9}>
        <Select
          key="operand"
          placeholder="Operand"
          options={operands}
          value={selectedOperand}
          onChange={(value) => {
            setSelectedOperand(value);
            setSelectedValue(null);
            setSelectedOperandIsManual(manualOperands.includes(value));
            setSelectedOperator(
              manualOperands.includes(value)
                ? 'set'
                : value === 'skuEntitlement'
                ? 'contains'
                : 'equals'
            );
          }}
          style={{ width: '100%' }}
        />
      </Col>
      <Col span={5} push={selectedOperandIsManual ? 10 : 0}>
        <Select
          key="operator"
          placeholder="is"
          options={operators}
          value={selectedOperator}
          onChange={setSelectedOperator}
          style={{ width: '100%' }}
        />
      </Col>
      <Col span={10} pull={selectedOperandIsManual ? 5 : 0}>
        <Select
          key="value"
          showSearch
          placeholder="value"
          options={valueOptions}
          value={selectedValue}
          onChange={setSelectedValue}
          disabled={!selectedOperand}
          style={{ width: '100%' }}
          dropdownMatchSelectWidth={false}
        />
      </Col>
    </Row>
  );

  function checkForOperandValue(
    operand: TConditionalOperands,
    value: TConditionalValues
  ): {
    status: 'available' | 'disabled' | 'locked';
    message: React.ReactNode;
    onClick?: () => void;
  } {
    if (operand === 'sku' && value === 'featured') {
      const status = templateCapabilities?.includes('featured_product')
        ? 'available'
        : 'disabled';
      return {
        status: status,
        message:
          status === 'disabled' ? (
            <>
              <UpgradeButton
                size="small"
                type="link"
                icon={<ToolOutlined />}
                onClick={() => {
                  onClose();
                  openCapabilitiesTab();
                }}
              >
                Add the Featured Capability
              </UpgradeButton>
            </>
          ) : (
            ''
          ),
        onClick: status === 'disabled' ? openCapabilitiesTab : undefined,
      };
    }
    if (operand === 'sku' && value === 'selected') {
      const status = templateCapabilities?.includes('selected_product')
        ? 'available'
        : 'disabled';
      return {
        status: status,
        message:
          status === 'disabled' ? (
            <>
              <UpgradeButton
                size="small"
                type="link"
                icon={<ToolOutlined />}
                onClick={() => {
                  onClose();
                  openCapabilitiesTab();
                }}
              >
                Add the Selected Capability
              </UpgradeButton>
            </>
          ) : (
            ''
          ),
        onClick: status === 'disabled' ? openCapabilitiesTab : undefined,
      };
    }
    if (value === 'freeTrialEligible') {
      const status = templateCapabilities?.includes('conditional_product_trial')
        ? 'available'
        : 'disabled';
      return {
        status: status,
        message:
          status === 'disabled' ? (
            <>
              <UpgradeButton
                size="small"
                type="link"
                icon={<ToolOutlined />}
                onClick={() => {
                  onClose();
                  openCapabilitiesTab();
                }}
              >
                Add the Trial Eligibility Capability
              </UpgradeButton>
            </>
          ) : (
            ''
          ),
        onClick: status === 'disabled' ? openCapabilitiesTab : undefined,
      };
    }
    if (value === 'promoEligible') {
      const status = templateCapabilities?.includes('conditional_product_offer')
        ? 'available'
        : 'disabled';
      return {
        status: status,
        message:
          status === 'disabled' ? (
            <>
              <UpgradeButton
                size="small"
                type="link"
                icon={<ToolOutlined />}
                onClick={() => {
                  onClose();
                  openCapabilitiesTab();
                }}
              >
                Add the Offer Eligibility Capability
              </UpgradeButton>
            </>
          ) : (
            ''
          ),
        onClick: status === 'disabled' ? openCapabilitiesTab : undefined,
      };
    }
    if (value === 'introEligible') {
      const status = templateCapabilities?.includes('conditional_product_intro')
        ? 'available'
        : 'disabled';
      return {
        status: status,
        message:
          status === 'disabled' ? (
            <>
              <UpgradeButton
                size="small"
                type="link"
                icon={<ToolOutlined />}
                onClick={() => {
                  onClose();
                  openCapabilitiesTab();
                }}
              >
                Add the Intro Eligibility Capability
              </UpgradeButton>
            </>
          ) : (
            ''
          ),
        onClick: status === 'disabled' ? openCapabilitiesTab : undefined,
      };
    }
    if (operand === 'user' && value === 'isLoggedIn') {
      const status = templateCapabilities?.includes('dynamic_signin')
        ? 'available'
        : 'disabled';
      return {
        status: status,
        message:
          status === 'disabled' ? (
            <>
              <UpgradeButton
                size="small"
                type="link"
                icon={<ToolOutlined />}
                onClick={() => {
                  onClose();
                  openCapabilitiesTab();
                }}
              >
                Add the Login Check Capability
              </UpgradeButton>
            </>
          ) : (
            ''
          ),
        onClick: status === 'disabled' ? openCapabilitiesTab : undefined,
      };
    }
    return {
      status: 'available',
      message: '',
    };
  }
}
