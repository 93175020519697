import React from 'react';

import { Col, Drawer, Row, Space } from 'antd';
import { createSelector } from 'reselect';
import { useAppSelector } from 'src/hooks/redux.hooks';
import { RootState } from 'src/redux';
import { namiPureWhite } from 'src/variables';

import PaywallCustomAttributeDictionary from './PaywallCustomAttributeDictionary';
import PaywallCustomAttributeSetter from './PaywallCustomAttributeSetter';
import PaywallCustomObjectSetter from './PaywallCustomObjectSetter';
import PaywallDarkModePicker from './PaywallDarkModePicker';
import PaywallFlowPicker from './PaywallFlowPicker';
import PaywallFocusedStatePicker from './PaywallFocusedStatePicker';
import PaywallPagePicker from './PaywallPagePicker';
import PaywallPersonalizationTokenSetter from './PaywallPersonalizationTokenSetter';
import PaywallPlatformPicker from './PaywallPlatformPicker';
import PaywallSigninStatePicker from './PaywallSigninStatePicker';
import PaywallVideoPlayStatePicker from './PaywallVideoPlayStatePicker';
import PaywallVideoVolumeStatePicker from './PaywallVideoVolumeStatePicker';
import ProductGroupLaunchPicker from './ProductGroupLaunchPicker';
import ProductPricingMultiStatePicker from './ProductPricingMultiStatePicker';
import ProductOfferStatePicker from './ProductPricingStatePicker';
import SingleProductGroupPicker from './SingleProductGroupPicker';

const selector = createSelector(
  [
    ({ paywallBuilder: { paywall } }: RootState) =>
      paywall?.template['ui.capabilities'],
    ({ paywallBuilder: { paywall } }: RootState) =>
      paywall?.template['ui.requiredGroups'],
    ({ paywallBuilder: { paywall } }: RootState) =>
      paywall?.template['ui.displaySingleGroup'],
    ({ paywallBuilder: { paywall } }: RootState) =>
      paywall?.template['ui.formFactors'],
    ({ paywallBuilder: { paywall } }: RootState) =>
      paywall?.template['ui.v2Ready'],
    ({ paywallBuilder: { paywall } }: RootState) =>
      paywall?.template['ui.launchCustomAttributes'],
  ],
  (
    capabilities,
    requiredGroups,
    displaySingleGroup,
    formFactors,
    v2Ready,
    customAttributeOptions
  ) => ({
    showDarkMode: capabilities?.includes('dark_mode'),
    requiredGroups: requiredGroups || 0,
    displaySingleGroup,
    showCustomLaunchContext: capabilities?.includes('custom_launch_context'),
    showCustomObject: capabilities?.includes('custom_launch_object'),
    televisionFormFactor: formFactors?.[0]?.form_factor === 'television',
    alwaysShowOfferEligibility: capabilities?.includes(
      'conditional_product_offer'
    ),
    alwaysShowIntroEligibility: capabilities?.includes(
      'conditional_product_intro'
    ),
    toggleOfferEligibility: capabilities?.includes('toggle_product_offer'),
    alwaysShowTrialEligibility: capabilities?.includes(
      'conditional_product_trial'
    ),
    toggleTrialEligiblity: capabilities?.includes('toggle_product_trial'),
    showSigninState: capabilities?.includes('dynamic_signin'),
    multiPage: capabilities?.includes('multipage'),
    personalizationTokens: capabilities?.includes('personalization'),
    v2Ready: !Array.isArray(v2Ready) && v2Ready,
    showLaunchProductGroups: capabilities?.includes(
      'conditional_product_groups'
    ),
    customAttributeOptions: customAttributeOptions || {},
    showVideoControls: capabilities?.includes('video'),
    showFlowControls: capabilities?.includes('flow'),
  })
);

export default function PaywallPreviewHeader() {
  const {
    showDarkMode,
    requiredGroups,
    displaySingleGroup,
    showCustomLaunchContext,
    showCustomObject,
    televisionFormFactor,
    multiPage,
    personalizationTokens,
    alwaysShowOfferEligibility,
    toggleOfferEligibility,
    alwaysShowTrialEligibility,
    alwaysShowIntroEligibility,
    toggleTrialEligiblity,
    showSigninState,
    v2Ready,
    showLaunchProductGroups,
    customAttributeOptions,
    showVideoControls,
    showFlowControls,
  } = useAppSelector(selector);

  return (
    <Drawer
      bodyStyle={{ background: namiPureWhite, padding: 8, overflow: 'hidden' }}
      placement="top"
      open={true}
      height="42px"
      closable={false}
      zIndex={1000}
      mask={false}
      style={{ top: 56, overflow: 'hidden' }}
      className="horizontalScrollTable"
    >
      <Row justify="center" align="middle">
        <Col>
          <Space direction="horizontal" size="small">
            {<PaywallPlatformPicker />}
            {multiPage && <PaywallPagePicker />}
            {showDarkMode && <PaywallDarkModePicker />}
            {requiredGroups > 1 && displaySingleGroup && (
              <SingleProductGroupPicker />
            )}
            {requiredGroups > 1 && showLaunchProductGroups && (
              <ProductGroupLaunchPicker />
            )}
            {!v2Ready &&
              (alwaysShowOfferEligibility || toggleOfferEligibility) && (
                <ProductOfferStatePicker
                  includeIntroPromo={true}
                  toggleEligibility={!!toggleOfferEligibility}
                />
              )}
            {!v2Ready &&
              (alwaysShowTrialEligibility || toggleTrialEligiblity) && (
                <ProductOfferStatePicker
                  includeIntroPromo={false}
                  toggleEligibility={!!toggleTrialEligiblity}
                />
              )}
            {v2Ready &&
              (alwaysShowOfferEligibility ||
                alwaysShowTrialEligibility ||
                alwaysShowIntroEligibility) && (
                <ProductPricingMultiStatePicker
                  includeTrial={alwaysShowTrialEligibility || false}
                  includeIntro={alwaysShowIntroEligibility || false}
                  includePromo={alwaysShowOfferEligibility || false}
                />
              )}
            {showSigninState && <PaywallSigninStatePicker />}
            {showFlowControls && <PaywallFlowPicker />}
            {showVideoControls && <PaywallVideoPlayStatePicker />}
            {showVideoControls && <PaywallVideoVolumeStatePicker />}
            {showCustomLaunchContext &&
              !v2Ready &&
              !Object.keys(customAttributeOptions).length && (
                <PaywallCustomAttributeSetter />
              )}
            {showCustomLaunchContext &&
              (v2Ready || Object.keys(customAttributeOptions).length > 0) && (
                <PaywallCustomAttributeDictionary />
              )}
            {showCustomObject && v2Ready && <PaywallCustomObjectSetter />}
            {televisionFormFactor && <PaywallFocusedStatePicker />}
            {personalizationTokens && <PaywallPersonalizationTokenSetter />}
          </Space>
        </Col>
      </Row>
    </Drawer>
  );
}
