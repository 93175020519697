/* eslint-disable no-template-curly-in-string */
import { TIconName } from 'src/components/AntIcon';
import { TVariableMeta } from 'src/pages/admin/paywalls/utils/componentGeneration';

import { TDevice } from './paywall.types';

export type TField =
  | 'image'
  | 'imagePicker'
  | 'fallbackImagePicker'
  | 'listContainerComponent'
  | 'text'
  | 'url'
  | 'textArea'
  | 'fontSelect'
  | 'splitTextArea'
  | 'color'
  | 'colorGradient'
  | 'number'
  | 'alignment'
  | 'horizontalAlignment'
  | 'verticalAlignment'
  | 'carouselSlides'
  | 'option'
  | 'iconSelect'
  | 'toggle'
  | 'dropShadow'
  | 'bigNumber'
  | 'width'
  | 'widthFillFixed'
  | 'height'
  | 'heightFillFixed'
  | 'direction'
  | 'imageCropping'
  | 'safeArea'
  | 'ptPxSelect'
  | 'conditionBuilder'
  | 'roundBorders'
  | 'productDataSource'
  | 'productVariableSelector'
  | 'conditionalProductVariableSelector'
  | 'onTap'
  | 'carouselTextInputSelector'
  | 'carouselImageInputSelector'
  | 'collapseOpenToggle'
  | 'dateTimeFormat';
export const FULL_SIZE_FIELDS = new Set([
  'image',
  'imagePicker',
  'fallbackImagePicker',
  'url',
  'text',
  'textArea',
  'fontSelect',
  'splitTextArea',
  'carouselSlides',
  'option',
  'listContainerComponent',
  'direction',
  'width',
  'widthFillFixed',
  'height',
  'heightFillFixed',
  'toggle',
  'productDataSource',
  'productVariableSelector',
  'conditionalProductVariableSelector',
  'onTap',
  'carouselTextInputSelector',
  'carouselImageInputSelector',
  'dateTimeFormat',
]) as Set<TField>;
export type TVariablePattern = `\${var.${string}}`;
export type TSpecialPOS =
  | 'collapseHeader'
  | 'playingComponents'
  | 'pausedComponents'
  | 'volumeComponents'
  | 'mutedComponents';
export type TPOS = number | TSpecialPOS;
export type TPaywallPageSection =
  | 'contentContainer'
  | 'header'
  | 'footer'
  | 'backgroundContainer';
export type TComponentLocation = {
  page: number;
  section: TPaywallPageSection;
  pos: TPOS[];
};
export type TComponentLocationMetadata = {
  section: TPaywallPageSection;
  type: TAllComponentDetailValues;
};
export type AlignmentType = 'center' | 'right' | 'left' | 'top' | 'bottom';
export type BorderLocationType =
  | 'upperLeft'
  | 'upperRight'
  | 'lowerLeft'
  | 'lowerRight';
export type BorderSideType = 'left' | 'right' | 'top' | 'bottom';
export type DirectionType = 'vertical' | 'horizontal';
export type TContainerPosition = `${'center' | 'start' | 'end'}-${
  | 'top'
  | 'bottom'
  | 'left'
  | 'right'}`;
export const BorderMap: Record<BorderLocationType, string> = {
  upperLeft: 'border-top-left-radius',
  upperRight: 'border-top-right-radius',
  lowerLeft: 'border-bottom-left-radius',
  lowerRight: 'border-bottom-right-radius',
};
export const BorderSideMap: Record<BorderSideType, string> = {
  left: 'border-left-style',
  right: 'border-right-style',
  top: 'border-top-style',
  bottom: 'border-bottom-style',
};

export type TCarouselSlidesState = {
  [groupId: string]: { [carouselName: string]: TCarouselSlide[] };
};
export type TCarouselSlide = {
  id: string;
  title: string;
  [slideAttr: string]: any;
};
export type TCarouselSettings = {
  [carouselName: string]: {
    newSlide: Omit<TCarouselSlide, 'id'>;
    maxSlides: number;
    slides: TCarouselSlide[];
    fields: TFieldSettings[];
    productGroupSlides?: boolean;
  };
};
export type TListSubComponent =
  | 'listNamiIcon'
  | 'listContent'
  | 'listImage'
  | 'listIcon';

export type TFieldSettings = {
  type: TField;
  label: string;
  variable: string;
  hint?: string | null;
  description?: string | null;
  placeholder?: string | null;
  readOnly?: boolean;
  markdownHint?: boolean;
  options?: { label: string; value: any }[] | null;
  maxLimit?: number | null;
  minLimit?: number | null;
  aspectRatio?: number | null;
  newSlide?: TCarouselSlide;
  carousel?: string;
  darkModeVariable?: string;
  conditions?: TTestObject[];
  showOpacity?: boolean;
  newRow?: any;
  availableListComponents?: TListSubComponent[];
  componentLocation?: TComponentLocation;
  showVariableCloud?: boolean;
  parentDataSourceRoot?: string | null;
};

export interface TBaseComponent {
  component: TBaseComponentTypes;
  grow?: TVariablePattern | boolean;
  flag?: null | string;
  context?: { [key: string]: any };
  moveX?: `${number}%` | number;
  moveY?: `${number}%` | number;
  direction?: TVariablePattern | DirectionType;
  spacing?: TVariablePattern | number;
  alignment?: TVariablePattern | AlignmentType;
  horizontalAlignment?: TVariablePattern | AlignmentType;
  verticalAlignment?: TVariablePattern | AlignmentType;
  leftPadding?: TVariablePattern | number;
  rightPadding?: TVariablePattern | number;
  topPadding?: TVariablePattern | number;
  bottomPadding?: TVariablePattern | number;
  leftMargin?: TVariablePattern | number;
  rightMargin?: TVariablePattern | number;
  topMargin?: TVariablePattern | number;
  bottomMargin?: TVariablePattern | number;
  fillColor?: TVariablePattern | string;
  borderWidth?: TVariablePattern | number;
  borderRadius?: TVariablePattern | number;
  borderColor?: string;
  borders?: Array<BorderSideType>;
  focusedBorders?: Array<BorderSideType>;
  focusedFillColor?: string;
  focusedFontColor?: string;
  focusedBorderColor?: string;
  focusedBorderWidth?: number;
  focusedBorderRadius?: number;
  roundBorders?: Array<BorderLocationType> | TVariablePattern;
  focusedRoundBorders?: Array<BorderLocationType>;
  zIndex?: TVariablePattern | number;
  conditionAttributes?: Array<
    Omit<TConditionalComponent, 'components'> & {
      attributes: Partial<TBaseComponent | TTextComponent | TSymbolComponent>;
    }
  >;
  height?: number | 'fitContent' | `${number}%`;
  width?: number | 'fitContent' | `${number}%`;
  dropShadow?: string;
  refId?: TListSubComponent;
  id?: string;
  namiComponentType?: TAllComponentDetailValues;
  title?: string;
  // TODO: Deprecate the attributes below
  _fields?: { [attribute: string]: TFieldSettings } & {
    _group: string;
    _label: string;
    _toggleAttr: string | null;
    _toggleValue: any;
    _collapsible: boolean;
    _hint: string | null;
  };
  _fieldGroupLabel?: string;
  _fieldLabel?: string;
  _fieldHint?: string;
  _fieldReadOnly?: boolean;
  _fieldPlaceholder?: string;
  _fieldDescription?: string;
  _fieldOmit?: string[];
  fixedHeight?: number;
  fixedWidth?: number | 'fitContent';
  hidden?: boolean;
}

export type TBaseComponentTypes =
  | 'text'
  | 'spacer'
  | 'image'
  | 'svgImage'
  | 'spacer'
  | 'button'
  | 'container'
  | 'condition'
  | 'symbol'
  | 'text-list'
  | 'productContainer'
  | 'carouselContainer'
  | 'collapseContainer'
  | 'checkbox'
  | 'segmentPicker'
  | 'segmentPickerItem'
  | 'stack'
  | 'videoUrl'
  | 'responsiveGrid'
  | 'playPauseButton'
  | 'volumeButton';

type TFlatComponentDetailValues =
  | 'titleText'
  | 'bodyText'
  | 'buttonText'
  | 'textBlock'
  | 'legalText'
  | 'bulletComponent'
  | 'icon'
  | 'group'
  | 'image'
  | 'hostedImage'
  | 'videoUrl'
  | 'condition'
  | 'spacer'
  | 'divider'
  | 'productGroupToggleItem'
  | 'linkButton'
  | 'repeatingTextSource'
  | 'repeatingImageSource';

type TFlatProductComponentDetailValues =
  | 'productText'
  | 'productWrapper'
  | 'productBadge';

export type TContainerComponentDetailValues =
  | 'contentContainer'
  | 'header'
  | 'backgroundContainer'
  | 'bulletList'
  | 'customList'
  | 'listItem'
  | 'loginButton'
  | 'restoreButton'
  | 'closeButton'
  | 'purchaseButton'
  | 'deeplinkButton'
  | 'navigatePageButton'
  | 'carousel'
  | 'slide'
  | 'productGroupToggle'
  | 'footer'
  | 'collapse'
  | 'collapseHeader'
  | 'collapseBody'
  | 'stack'
  | 'repeatingList'
  | 'playPauseButton'
  | 'volumeButton'
  | 'flowButton';

export type TProductContainerComponentDetailValues =
  | 'productButton'
  | 'productContainer';

export type TComponentDetailValues =
  | TFlatComponentDetailValues
  | TContainerComponentDetailValues;

export type TProductComponentDetailValues =
  | TFlatProductComponentDetailValues
  | TProductContainerComponentDetailValues;

export type TAllContainerDetailValues =
  | TContainerComponentDetailValues
  | TProductContainerComponentDetailValues;

export type TAllComponentDetailValues =
  | TComponentDetailValues
  | TProductComponentDetailValues;

export type TNewComponentBuilderMeta = {
  variables: { [key: string]: TVariableMeta };
  skuVariables: { [key: string]: TVariableMeta };
  slideVariables: { [key: string]: TVariableMeta };
  stateVariables: { [key: string]: TVariableMeta };
  slideFormFields: TFieldSettings[];
  component: TComponent;
};

export type TEditComponentBuilderMeta = {
  variables: { [key: string]: TVariableMeta };
  skuVariables: { [key: string]: TVariableMeta };
  component: TComponent;
};

export type TVariableNamespace = 'var' | 'sku' | 'media' | 'slide' | 'state';

export type TSpacerComponent = TBaseComponent & {
  component: 'spacer';
};

export type TImageComponent = TBaseComponent & {
  component: 'image';
  url?: string | null;
  aspectRatio: number;
  imageCropping: 'fill' | 'fit';
};

export type TVideoComponent = TBaseComponent & {
  component: 'videoUrl';
  url?: string | null;
  imageCropping: 'fill' | 'fit'; //TODO
  autoplayVideo: boolean;
  mute: boolean;
  loopVideo: boolean;
  fallbackImage?: string | null;
  controlsType: 'none' | 'nativeUi' | 'custom';
};

export type TPlayPauseButtonComponent = TBaseComponent & {
  component: 'playPauseButton';
  pausedOnTap: any;
  playingOnTap: any;
  pausedComponents: Array<TComponent>;
  playingComponents: Array<TComponent>;
  screenreaderText?: TVariablePattern | string;
};

export type TVolumeControlComponent = TBaseComponent & {
  component: 'volumeButton';
  mutedOnTap: any;
  volumeOnTap: any;
  volumeComponents: Array<TComponent>;
  mutedComponents: Array<TComponent>;
  screenreaderText?: TVariablePattern | string;
};

export type TSvgImageComponent = TBaseComponent & {
  component: 'svgImage';
  url?: string | null;
  fontColor?: string;
};

export type TTextComponent = TBaseComponent & {
  component: 'text';
  textType: 'title' | 'body' | 'legal';
  androidFontSize?: number;
  fontSize?: TVariablePattern | number;
  fontColor?: string;
  fontName?: string;
  text: string;
  strikethrough?: TVariablePattern | boolean;
  linkColor?: string;
  dateTimeFormat?: string;
};

export type TSymbolComponent = Omit<
  TTextComponent,
  'component' | 'textType' | 'text'
> & {
  component: 'symbol';
  name: TVariablePattern | TIconName;
};

export type TTextListComponent = Omit<TTextComponent, 'component' | 'text'> & {
  component: 'text-list';
  texts: TVariablePattern | string[];
  bulletComponent?: TSymbolComponent;
};

export type TButtonContainer = TBaseComponent & {
  component: 'button';
  onTap?: any;
  actionTap: string;
  components: Array<TTextComponent | TTextListComponent | TSpacerComponent>;
  screenreaderText?: TVariablePattern | string;
};

export type TProductContainer = Omit<TContainer, 'component'> & {
  component: 'productContainer';
  products: 'all' | 'subset';
  subsetGroup?: string;
  productFeaturedComponents: TContainer[];
  productBaseComponents: TContainer[];
};

export type TTestObjectOperator =
  | 'equals'
  | 'contains'
  | 'notContains'
  | 'notEquals'
  | 'set'
  | 'notSet'
  | 'true'
  | 'false';

export const TTestObjectOperatorNameMap: Record<TTestObjectOperator, string> = {
  equals: 'equals',
  contains: 'contains',
  notContains: 'does not contain',
  notEquals: 'does not equal',
  set: 'is set',
  notSet: 'is not set',
  true: 'is true',
  false: 'is false',
};

export type TTestObject = {
  value: any;
  expected: any;
  operator: TTestObjectOperator;
};

export type TConditionalComponent = TBaseComponent & {
  component: 'condition';
  components?: TComponent[];
  assertions?: TTestObject[];
  orAssertions?: TTestObject[];
};

export type TConditionalOperands =
  | 'sku'
  | 'skuEntitlement'
  | 'skuVariable'
  | 'state'
  | 'user'
  | 'group'
  | 'launchGroup'
  | 'launchContext'
  | 'collapse'
  | 'launchObject'
  | 'appSuppliedVideo'
  | 'platform'
  | 'flow';

export type TConditionalValues =
  | 'featured'
  | 'selected'
  | 'darkMode'
  | 'fullScreenPresentation'
  | 'isLoggedIn'
  | 'hasNotch'
  | 'freeTrialEligible'
  | 'introEligible'
  | 'promoEligible'
  | 'group0'
  | 'group1'
  | 'true'
  | 'false'
  | 'portrait'
  | 'landscape'
  | 'google'
  | 'roku_pay'
  | 'amazon_iap'
  | 'apple'
  | 'web'
  | 'exists'
  | 'previousStepAvailable'
  | 'nextStepAvailable';

export const TAssertionMapToReadable: Record<string, string> = {
  '${sku.featured} equals true': 'Product is Featured',
  '${sku.featured} notEquals true': 'Product is not Featured',
  '${sku.featured} equals false': 'Product is not Featured',
  '${sku.featured notEquals false': 'Product is Featured',
  '${sku.freeTrialEligible} equals true': 'Product is Free Trial Eligible',
  '${sku.freeTrialEligible} equals false': 'Product is not Free Trial Eligible',
  '${sku.freeTrialEligible} notEquals true':
    'Product is not Free Trial Eligible',
  '${sku.freeTrialEligible} notEquals false': 'Product is Free Trial Eligible',
  '${sku.introEligible} equals true': 'Product is Intro Offer Eligible',
  '${sku.introEligible} equals false': 'Product is not Intro Offer Eligible',
  '${sku.introEligible} notEquals true': 'Product is not Intro Offer Eligible',
  '${sku.introEligible} notEquals false': 'Product is Intro Offer Eligible',
  '${sku.promoEligible} equals true': 'Product is Promo Offer Eligible',
  '${sku.promoEligible} equals false': 'Product is not Promo Offer Eligible',
  '${sku.promoEligible} notEquals true': 'Product is not Promo Offer Eligible',
  '${sku.promoEligible} notEquals false': 'Product is Promo Offer Eligible',
  '${state.fullScreenPresentation} equals true': 'Device is Fullscreen Mode',
  '${state.fullScreenPresentation} notEquals true':
    'Device is not Fullscreen Mode',
  '${state.fullScreenPresentation} equals false':
    'Device is not Fullscreen Mode',
  '${state.fullScreenPresentation} notEquals false':
    'Device is Fullscreen Mode',
  '${state.selectedProducts.${state.currentGroupId}} equals ${sku.id}':
    'Product is Selected',
  '${state.selectedProducts.${state.currentGroupId}} notEquals ${sku.id}':
    'Product is not Selected',
  '${state.isLoggedIn} equals true': 'User is Logged In',
  '${state.isLoggedIn} notEquals true': 'User is not Logged In',
  '${state.isLoggedIn} equals false': 'User is not Logged In',
  '${state.isLoggedIn} notEquals false': 'User is Logged In',
  '${state.hasNotch} equals true': 'Device is Notched',
  '${state.hasNotch} equals false': 'Device is not Notched',
  '${state.hasNotch} notEquals true': 'Device is not Notched',
  '${state.hasNotch} notEquals false': 'Device is Notched',
  '${state.anySkuHasTrialOffer} equals true': 'User is Free Trial Eligible',
  '${state.anySkuHasTrialOffer} equals false':
    'User is not Free Trial Eligible',
  '${state.anySkuHasTrialOffer} notEquals true':
    'User is not Free Trial Eligible',
  '${state.anySkuHasTrialOffer} notEquals false': 'User is Free Trial Eligible',
  '${state.anySkuHasIntroOffer} equals true': 'User is Intro Offer Eligible',
  '${state.anySkuHasIntroOffer} equals false':
    'User is not Intro Offer Eligible',
  '${state.anySkuHasIntroOffer} notEquals true':
    'User is not Intro Offer Eligible',
  '${state.anySkuHasIntroOffer} notEquals false':
    'User is Intro Offer Eligible',
  '${state.anySkuHasPromoOffer} equals true': 'User is Promo Offer Eligible',
  '${state.anySkuHasPromoOffer} equals false':
    'User is not Promo Offer Eligible',
  '${state.anySkuHasPromoOffer} notEquals true':
    'User is not Promo Offer Eligible',
  '${state.anySkuHasPromoOffer} notEquals false':
    'User is Promo Offer Eligible',
  '${launch.productGroups} set true': 'Launch Group is set',
  '${launch.productGroups} set false': 'Launch Group is not set',
  '${state.appSuppliedVideoUrl} set true': 'App Supplied Video is set',
  '${state.appSuppliedVideoUrl} notSet true': 'App Supplied Video is not set',
  '${state.orientation} equals portrait': 'Device Orientation is Portrait',
  '${state.orientation} equals landscape': 'Device Orientation is Landscape',
  '${state.orientation} notEquals portrait':
    'Device Orientation is not Portrait',
  '${state.orientation} notEquals landscape':
    'Device Orientation is not Landscape',
  '${platform.type} equals apple': 'Platform Type is Apple',
  '${platform.type} equals google': 'Platform Type is Google',
  '${platform.type} equals amazon_iap': 'Platform Type is Amazon',
  '${platform.type} equals roku_pay': 'Platform Type is Roku',
  '${platform.type} equals web': 'Platform Type is Web',
  '${platform.type} notEquals apple': 'Platform Type is not Apple',
  '${platform.type} notEquals google': 'Platform Type is not Google',
  '${platform.type} notEquals amazon_iap': 'Platform Type is not Amazon',
  '${platform.type} notEquals roku_pay': 'Platform Type is not Roku',
  '${platform.type} notEquals web': 'Platform Type is not Web',
  '${flow.exists} equals true': 'Flow has this Paywall',
  '${flow.exists} equals false': "Flow doesn't have this Paywall",
  '${flow.exists} notEquals true': "Flow doesn't have this Paywall",
  '${flow.exists} notEquals false': 'Flow has this Paywall',
  '${flow.previousStepAvailable} equals true': 'Flow has Previous Step',
  '${flow.previousStepAvailable} equals false':
    "Flow doesn't have Previous Step",
  '${flow.previousStepAvailable} notEquals true':
    "Flow doesn't have Previous Step",
  '${flow.previousStepAvailable} notEquals false': 'Flow has Previous Step',
  '${flow.nextStepAvailable} equals true': 'Flow has Next Step',
  '${flow.nextStepAvailable} equals false': "Flow doesn't have Next Step",
  '${flow.nextStepAvailable} notEquals true': "Flow doesn't have Next Step",
  '${flow.nextStepAvailable} notEquals false': 'Flow has Next Step',
};

export const TParsedToAssertionMap: Record<string, TTestObject> = {
  'sku equals featured': {
    value: '${sku.featured}',
    expected: true,
    operator: 'equals',
  },
  'sku notEquals featured': {
    value: '${sku.featured}',
    expected: true,
    operator: 'notEquals',
  },
  'sku equals freeTrialEligible': {
    value: '${sku.freeTrialEligible}',
    expected: true,
    operator: 'equals',
  },
  'sku notEquals freeTrialEligible': {
    value: '${sku.freeTrialEligible}',
    expected: false,
    operator: 'equals',
  },
  'sku equals introEligible': {
    value: '${sku.introEligible}',
    expected: true,
    operator: 'equals',
  },
  'sku notEquals introEligible': {
    value: '${sku.introEligible}',
    expected: false,
    operator: 'equals',
  },
  'sku equals promoEligible': {
    value: '${sku.promoEligible}',
    expected: true,
    operator: 'equals',
  },
  'sku notEquals promoEligible': {
    value: '${sku.promoEligible}',
    expected: false,
    operator: 'equals',
  },
  'state equals fullScreenPresentation': {
    value: '${state.fullScreenPresentation}',
    expected: true,
    operator: 'equals',
  },
  'state notEquals fullScreenPresentation': {
    value: '${state.fullScreenPresentation}',
    expected: true,
    operator: 'notEquals',
  },
  'sku equals selected': {
    value: '${state.selectedProducts.${state.currentGroupId}}',
    expected: '${sku.id}',
    operator: 'equals',
  },
  'sku notEquals selected': {
    value: '${state.selectedProducts.${state.currentGroupId}}',
    expected: '${sku.id}',
    operator: 'notEquals',
  },
  'user equals isLoggedIn': {
    value: '${state.isLoggedIn}',
    expected: true,
    operator: 'equals',
  },
  'user notEquals isLoggedIn': {
    value: '${state.isLoggedIn}',
    expected: true,
    operator: 'notEquals',
  },
  'state equals hasNotch': {
    value: '${state.hasNotch}',
    expected: true,
    operator: 'equals',
  },
  'state notEquals hasNotch': {
    value: '${state.hasNotch}',
    expected: false,
    operator: 'equals',
  },
  'user equals freeTrialEligible': {
    value: '${state.anySkuHasTrialOffer}',
    expected: true,
    operator: 'equals',
  },
  'user notEquals freeTrialEligible': {
    value: '${state.anySkuHasTrialOffer}',
    expected: false,
    operator: 'equals',
  },
  'user equals promoEligible': {
    value: '${state.anySkuHasPromoOffer}',
    expected: true,
    operator: 'equals',
  },
  'user notEquals promoEligible': {
    value: '${state.anySkuHasPromoOffer}',
    expected: false,
    operator: 'equals',
  },
  'user equals introEligible': {
    value: '${state.anySkuHasIntroOffer}',
    expected: true,
    operator: 'equals',
  },
  'user notEquals introEligible': {
    value: '${state.anySkuHasIntroOffer}',
    expected: false,
    operator: 'equals',
  },
  'group equals group0': {
    value: '${state.groups.0.id}',
    expected: '${state.currentGroupId}',
    operator: 'equals',
  },
  'group notEquals group0': {
    value: '${state.groups.0.id}',
    expected: '${state.currentGroupId}',
    operator: 'notEquals',
  },
  'group equals group1': {
    value: '${state.groups.1.id}',
    expected: '${state.currentGroupId}',
    operator: 'equals',
  },
  'group notEquals group1': {
    value: '${state.groups.1.id}',
    expected: '${state.currentGroupId}',
    operator: 'notEquals',
  },
  'launchGroup equals group0': {
    value: '${launch.productGroups}',
    operator: 'contains',
    expected: '${state.groups.0.ref}',
  },
  'launchGroup equals group1': {
    value: '${launch.productGroups}',
    operator: 'contains',
    expected: '${state.groups.1.ref}',
  },
  'launchGroup set true': {
    value: '${launch.productGroups}',
    operator: 'set',
    expected: true,
  },
  'launchGroup set null': {
    value: '${launch.productGroups}',
    operator: 'set',
    expected: true,
  },
  'launchGroup notSet true': {
    value: '${launch.productGroups}',
    operator: 'set',
    expected: false,
  },
  'launchGroup notSet null': {
    value: '${launch.productGroups}',
    operator: 'set',
    expected: false,
  },
  'appSuppliedVideo equals true': {
    value: '${state.appSuppliedVideoUrl}',
    operator: 'set',
    expected: true,
  },
  'appSuppliedVideo equals false': {
    value: '${state.appSuppliedVideoUrl}',
    operator: 'set',
    expected: false,
  },
  'appSuppliedVideo notEquals true': {
    value: '${state.appSuppliedVideoUrl}',
    operator: 'set',
    expected: false,
  },
  'appSuppliedVideo notEquals false': {
    value: '${state.appSuppliedVideoUrl}',
    operator: 'set',
    expected: true,
  },
  'state equals portrait': {
    value: '${state.orientation}',
    operator: 'equals',
    expected: 'portrait',
  },
  'state notEquals portrait': {
    value: '${state.orientation}',
    operator: 'notEquals',
    expected: 'portrait',
  },
  'state equals landscape': {
    value: '${state.orientation}',
    operator: 'equals',
    expected: 'landscape',
  },
  'state notEquals landscape': {
    value: '${state.orientation}',
    operator: 'notEquals',
    expected: 'landscape',
  },
  'platform equals apple': {
    value: '${platform.type}',
    operator: 'equals',
    expected: 'apple',
  },
  'platform notEquals apple': {
    value: '${platform.type}',
    operator: 'notEquals',
    expected: 'apple',
  },
  'platform equals google': {
    value: '${platform.type}',
    operator: 'equals',
    expected: 'google',
  },
  'platform notEquals google': {
    value: '${platform.type}',
    operator: 'notEquals',
    expected: 'google',
  },
  'platform equals amazon_iap': {
    value: '${platform.type}',
    operator: 'equals',
    expected: 'amazon_iap',
  },
  'platform notEquals amazon_iap': {
    value: '${platform.type}',
    operator: 'notEquals',
    expected: 'amazon_iap',
  },
  'platform equals roku_pay': {
    value: '${platform.type}',
    operator: 'equals',
    expected: 'roku_pay',
  },
  'platform notEquals roku_pay': {
    value: '${platform.type}',
    operator: 'notEquals',
    expected: 'roku_pay',
  },
  'platform equals web': {
    value: '${platform.type}',
    operator: 'equals',
    expected: 'web',
  },
  'platform notEquals web': {
    value: '${platform.type}',
    operator: 'notEquals',
    expected: 'web',
  },
  'flow equals exists': {
    value: '${flow.exists}',
    operator: 'equals',
    expected: true,
  },
  'flow notEquals exists': {
    value: '${flow.exists}',
    operator: 'notEquals',
    expected: true,
  },
  'flow equals previousStepAvailable': {
    value: '${flow.previousStepAvailable}',
    operator: 'equals',
    expected: true,
  },
  'flow notEquals previousStepAvailable': {
    value: '${flow.previousStepAvailable}',
    operator: 'notEquals',
    expected: true,
  },
  'flow equals nextStepAvailable': {
    value: '${flow.nextStepAvailable}',
    operator: 'equals',
    expected: true,
  },
  'flow notEquals nextStepAvailable': {
    value: '${flow.nextStepAvailable}',
    operator: 'notEquals',
    expected: true,
  },
};

export const TAssertionMapToParsed: Record<
  string,
  {
    operand: TConditionalOperands;
    operator: TTestObjectOperator;
    value: TConditionalValues;
  }
> = {
  '${state.groups.0.id} equals ${state.currentGroupId}': {
    operand: 'group',
    operator: 'equals',
    value: 'group0',
  },
  '${state.groups.0.id} notEquals ${state.currentGroupId}': {
    operand: 'group',
    operator: 'notEquals',
    value: 'group0',
  },
  '${state.groups.1.id} equals ${state.currentGroupId}': {
    operand: 'group',
    operator: 'equals',
    value: 'group1',
  },
  '${state.groups.1.id} notEquals ${state.currentGroupId}': {
    operand: 'group',
    operator: 'notEquals',
    value: 'group1',
  },
  '${launch.productGroups} contains ${state.groups.0.ref}': {
    operand: 'launchGroup',
    operator: 'equals',
    value: 'group0',
  },
  '${launch.productGroups} contains ${state.groups.1.ref}': {
    operand: 'launchGroup',
    operator: 'equals',
    value: 'group1',
  },
  '${launch.productGroups} set true': {
    operand: 'launchGroup',
    operator: 'set',
    value: 'true',
  },
  '${launch.productGroups} set false': {
    operand: 'launchGroup',
    operator: 'notSet',
    value: 'true',
  },
  '${state.selectedProducts.${state.currentGroupId}} equals ${sku.id}': {
    operand: 'sku',
    operator: 'equals',
    value: 'selected',
  },
  '${state.selectedProducts.${state.currentGroupId}} notEquals ${sku.id}': {
    operand: 'sku',
    operator: 'notEquals',
    value: 'selected',
  },
  '${state.isLoggedIn} equals true': {
    operand: 'user',
    operator: 'equals',
    value: 'isLoggedIn',
  },
  '${state.isLoggedIn} notEquals true': {
    operand: 'user',
    operator: 'notEquals',
    value: 'isLoggedIn',
  },
  '${state.isLoggedIn} equals false': {
    operand: 'user',
    operator: 'notEquals',
    value: 'isLoggedIn',
  },
  '${state.isLoggedIn} notEquals false': {
    operand: 'user',
    operator: 'equals',
    value: 'isLoggedIn',
  },
  '${sku.featured} equals true': {
    operand: 'sku',
    operator: 'equals',
    value: 'featured',
  },
  '${sku.featured} equals false': {
    operand: 'sku',
    operator: 'notEquals',
    value: 'featured',
  },
  '${sku.featured} notEquals true': {
    operand: 'sku',
    operator: 'notEquals',
    value: 'featured',
  },
  '${sku.featured} notEquals false': {
    operand: 'sku',
    operator: 'equals',
    value: 'featured',
  },
  '${sku.freeTrialEligible} equals true': {
    operand: 'sku',
    operator: 'equals',
    value: 'freeTrialEligible',
  },
  '${sku.freeTrialEligible} equals false': {
    operand: 'sku',
    operator: 'notEquals',
    value: 'freeTrialEligible',
  },
  '${sku.freeTrialEligible} notEquals true': {
    operand: 'sku',
    operator: 'notEquals',
    value: 'freeTrialEligible',
  },
  '${sku.freeTrialEligible} notEquals false': {
    operand: 'sku',
    operator: 'equals',
    value: 'freeTrialEligible',
  },
  '${sku.introEligible} equals true': {
    operand: 'sku',
    operator: 'equals',
    value: 'introEligible',
  },
  '${sku.introEligible} equals false': {
    operand: 'sku',
    operator: 'notEquals',
    value: 'introEligible',
  },
  '${sku.introEligible} notEquals true': {
    operand: 'sku',
    operator: 'notEquals',
    value: 'introEligible',
  },
  '${sku.introEligible} notEquals false': {
    operand: 'sku',
    operator: 'equals',
    value: 'introEligible',
  },
  '${sku.promoEligible} equals true': {
    operand: 'sku',
    operator: 'equals',
    value: 'promoEligible',
  },
  '${sku.promoEligible} equals false': {
    operand: 'sku',
    operator: 'notEquals',
    value: 'promoEligible',
  },
  '${sku.promoEligible} notEquals true': {
    operand: 'sku',
    operator: 'notEquals',
    value: 'promoEligible',
  },
  '${sku.promoEligible} notEquals false': {
    operand: 'sku',
    operator: 'equals',
    value: 'promoEligible',
  },
  '${state.fullScreenPresentation} equals true': {
    operand: 'state',
    operator: 'equals',
    value: 'fullScreenPresentation',
  },
  '${state.fullScreenPresentation} equals false': {
    operand: 'state',
    operator: 'notEquals',
    value: 'fullScreenPresentation',
  },
  '${state.fullScreenPresentation} notEquals true': {
    operand: 'state',
    operator: 'notEquals',
    value: 'fullScreenPresentation',
  },
  '${state.fullScreenPresentation} notEquals false': {
    operand: 'state',
    operator: 'equals',
    value: 'fullScreenPresentation',
  },
  '${state.hasNotch} equals true': {
    operand: 'state',
    operator: 'equals',
    value: 'hasNotch',
  },
  '${state.hasNotch} equals false': {
    operand: 'state',
    operator: 'notEquals',
    value: 'hasNotch',
  },
  '${state.hasNotch} notEquals true': {
    operand: 'state',
    operator: 'notEquals',
    value: 'hasNotch',
  },
  '${state.hasNotch} notEquals false': {
    operand: 'state',
    operator: 'equals',
    value: 'hasNotch',
  },
  '${state.anySkuHasTrialOffer} equals true': {
    operand: 'user',
    operator: 'equals',
    value: 'freeTrialEligible',
  },
  '${state.anySkuHasTrialOffer} equals false': {
    operand: 'user',
    operator: 'notEquals',
    value: 'freeTrialEligible',
  },
  '${state.anySkuHasIntroOffer} equals true': {
    operand: 'user',
    operator: 'equals',
    value: 'introEligible',
  },
  '${state.anySkuHasIntroOffer} equals false': {
    operand: 'user',
    operator: 'notEquals',
    value: 'introEligible',
  },
  '${state.anySkuHasPromoOffer} equals true': {
    operand: 'user',
    operator: 'equals',
    value: 'promoEligible',
  },
  '${state.anySkuHasPromoOffer} equals false': {
    operand: 'user',
    operator: 'notEquals',
    value: 'promoEligible',
  },
  '${state.appSuppliedVideoUrl} set true': {
    operand: 'appSuppliedVideo',
    operator: 'equals',
    value: 'true',
  },
  '${state.appSuppliedVideoUrl} set false': {
    operand: 'appSuppliedVideo',
    operator: 'notEquals',
    value: 'true',
  },
  '${state.appSuppliedVideoUrl} notSet true': {
    operand: 'appSuppliedVideo',
    operator: 'notEquals',
    value: 'true',
  },
  '${state.appSuppliedVideoUrl} notSet false': {
    operand: 'appSuppliedVideo',
    operator: 'equals',
    value: 'true',
  },
  '${state.orientation} equals portrait': {
    operand: 'state',
    operator: 'equals',
    value: 'portrait',
  },
  '${state.orientation} notEquals portrait': {
    operand: 'state',
    operator: 'notEquals',
    value: 'portrait',
  },
  '${state.orientation} equals landscape': {
    operand: 'state',
    operator: 'equals',
    value: 'landscape',
  },
  '${state.orientation} notEquals landscape': {
    operand: 'state',
    operator: 'notEquals',
    value: 'landscape',
  },
  '${platform.type} equals apple': {
    operand: 'platform',
    operator: 'equals',
    value: 'apple',
  },
  '${platform.type} notEquals apple': {
    operand: 'platform',
    operator: 'notEquals',
    value: 'apple',
  },
  '${platform.type} equals google': {
    operand: 'platform',
    operator: 'equals',
    value: 'google',
  },
  '${platform.type} notEquals google': {
    operand: 'platform',
    operator: 'notEquals',
    value: 'google',
  },
  '${platform.type} equals amazon_iap': {
    operand: 'platform',
    operator: 'equals',
    value: 'amazon_iap',
  },
  '${platform.type} notEquals amazon_iap': {
    operand: 'platform',
    operator: 'notEquals',
    value: 'amazon_iap',
  },
  '${platform.type} equals roku_pay': {
    operand: 'platform',
    operator: 'equals',
    value: 'roku_pay',
  },
  '${platform.type} notEquals roku_pay': {
    operand: 'platform',
    operator: 'notEquals',
    value: 'roku_pay',
  },
  '${platform.type} equals web': {
    operand: 'platform',
    operator: 'equals',
    value: 'web',
  },
  '${platform.type} notEquals web': {
    operand: 'platform',
    operator: 'notEquals',
    value: 'web',
  },
  '${flow.exists} equals true': {
    operand: 'flow',
    operator: 'equals',
    value: 'exists',
  },
  '${flow.exists} notEquals true': {
    operand: 'flow',
    operator: 'notEquals',
    value: 'exists',
  },
  '${flow.previousStepAvailable} equals true': {
    operand: 'flow',
    operator: 'equals',
    value: 'previousStepAvailable',
  },
  '${flow.previousStepAvailable} notEquals true': {
    operand: 'flow',
    operator: 'notEquals',
    value: 'previousStepAvailable',
  },
  '${flow.nextStepAvailable} equals true': {
    operand: 'flow',
    operator: 'equals',
    value: 'nextStepAvailable',
  },
  '${flow.nextStepAvailable} notEquals true': {
    operand: 'flow',
    operator: 'notEquals',
    value: 'nextStepAvailable',
  },
};

export type TContainer = TBaseComponent & {
  component: 'container';
  position?: TContainerPosition;
  fillColor?: string;
  fillImage?: string | null;
  components: TComponent[];
  interpolateComponents?: TComponent[];
  loopVariable?: string;
  loopSource?: any[] | string;
  loopSourceConditions?: TTestObject[];
  previousSlidePadding?: number;
  nextSlidePadding?: number;
  inactiveSlideScale?: number;
  slideSpacing?: number;
};

export type TCollapseContainer = TBaseComponent & {
  component: 'collapseContainer';
  open: boolean | string | TVariablePattern;
  position?: TContainerPosition;
  fillColor?: string;
  fillImage?: string | null;
  components: TComponent[];
  collapseHeader: TContainer;
  previousSlidePadding?: number;
  nextSlidePadding?: number;
};

export type TCheckboxContainer = TBaseComponent & {
  component: 'checkbox';
  components: TComponent[];
  active?: boolean;
  activeFillColor?: string;
  activeBorderColor?: string;
  activeBorderRadius?: number;
  activeBorderWidth?: number;
  inactiveFillColor?: string;
  inactiveBorderRadius?: number;
  inactiveBorderColor?: string;
  inactiveBorderWidth?: number;
};

export type TSegmentPicker = TBaseComponent & {
  component: 'segmentPicker';
  components: TSegmentPickerItem[];
};

export type TSegmentPickerItem = TBaseComponent & {
  component: 'segmentPickerItem';
  text?: string;
  alignment?: AlignmentType;
  activeFillColor?: string;
  activeBorderColor?: string;
  activeBorderRadius?: number;
  activeBorderWidth?: number;
  activeRoundBorders?: Array<BorderLocationType>;
  activeFontSize?: number;
  activeFontColor?: string;
  activeFontName?: string;
  inactiveBorderRadius?: number;
  inactiveBorderColor?: string;
  inactiveBorderWidth?: number;
  inactiveRoundBorders?: Array<BorderLocationType>;
  inactiveFontSize?: number;
  inactiveFontName?: string;
  inactiveFontColor?: string;
  leftPadding?: number;
  rightPadding?: number;
  topPadding?: number;
  bottomPadding?: number;
};

export type TCarouselContainer = Omit<TContainer, 'component'> & {
  component: 'carouselContainer';
  'ui.name': string;
  indicatorColor: string;
  activeIndicatorColor: string;
  autoplay: boolean;
  autoplaySeconds: number;
  showIndicators?: boolean;
  indicator?: TCarouselIndicatorContainer;
  activeIndicator?: TCarouselIndicatorContainer;
};

export type TCarouselIndicatorContainer = {
  component: 'container';
  fillColor?: TVariablePattern | string;
  borderWidth?: TVariablePattern | number;
  borderRadius?: TVariablePattern | number;
  borderColor?: string;
  height?: number | `${number}%`;
  width?: number | 'fitContent' | `${number}%`;
  dropShadow?: string;
  leftPadding?: TVariablePattern | number;
  rightPadding?: TVariablePattern | number;
  topPadding?: TVariablePattern | number;
  bottomPadding?: TVariablePattern | number;
  leftMargin?: TVariablePattern | number;
  rightMargin?: TVariablePattern | number;
  topMargin?: TVariablePattern | number;
  bottomMargin?: TVariablePattern | number;
};

export type TStack = Omit<TContainer, 'component'> & {
  component: 'stack';
};

export type TRepeatingList = Omit<TContainer, 'component'> & {
  component: 'responsiveGrid';
};

export type TComponent =
  | TButtonContainer
  | TContainer
  | TConditionalComponent
  | TTextListComponent
  | TTextComponent
  | TSpacerComponent
  | TImageComponent
  | TVideoComponent
  | TPlayPauseButtonComponent
  | TVolumeControlComponent
  | TSvgImageComponent
  | TSymbolComponent
  | TCarouselContainer
  | TProductContainer
  | TStack
  | TCollapseContainer
  | TSegmentPicker
  | TSegmentPickerItem
  | TRepeatingList;

export type TTextLikeComponent =
  | TTextComponent
  | TTextListComponent
  | TSymbolComponent;

export type TContainerLikeComponent =
  | TContainer
  | TCarouselContainer
  | TProductContainer
  | TRepeatingList;

export type TFormGroup = {
  title: string;
  icon?: TIconName;
  sections: TFormSection[];
  conditions?: TTestObject[] | undefined;
  orConditions?: TTestObject[] | undefined;
};

export type TFormSection = {
  title: string;
  collapsible: boolean;
  displayVar: string | null;
  hint: string | null;
  fields: (
    | 'productGroupSelector'
    | 'fullscreenToggle'
    | 'productPricingStateSelector'
    | 'productTrialStateSelector'
    | 'trialEligibilityToggle'
    | TFieldSettings
  )[];
  conditions?: TTestObject[] | undefined; //Not used in template creator
  orConditions?: TTestObject[] | undefined; //Not used in template creator
};

export type TProductVariable = {
  display_name: string;
  variable: string;
  type: TProductVariableType;
  value: string;
  offerType?: TProductVariableOfferType;
};

export type TProductVariableType = 'string' | 'offerKey' | 'boolean';

export type TProductVariableOfferType = 'trial' | 'intro' | 'promo';

export type TTemplateFormFactor = {
  form_factor: TDevice;
  supports_portrait: boolean;
  supports_landscape: boolean;
};

export type TTemplateCapability =
  | 'accessibility'
  | 'carousel'
  | 'dark_mode'
  | 'honest_paywall'
  | 'byo_creative'
  | 'google_fonts'
  | 'custom_font'
  | 'video'
  | 'product_groups'
  | 'offer_code'
  | 'conditional_product_groups'
  | 'custom_launch_context'
  | 'conditional_product_trial'
  | 'conditional_product_offer'
  | 'conditional_product_intro'
  | 'table'
  | 'multipage'
  | 'dynamic_signin'
  | 'personalization'
  | 'upload_list_v2_icons'
  | 'upload_list_v2_images'
  | 'toggle_product_trial'
  | 'toggle_product_offer'
  | 'featured_product'
  | 'selected_product'
  | 'conditionals'
  | 'deeplink'
  | 'custom_launch_object'
  | 'flow';

export type TPaywallPage = {
  name: string;
  header: Array<TContainer | TButtonContainer | TSpacerComponent>;
  contentContainer: TContainer;
  footer: TComponent[] | null;
  backgroundContainer: TContainer;
};

export const TPaywallCapabilityReadableNames: Record<
  TTemplateCapability,
  string
> = {
  accessibility: 'Screenreader Text',
  carousel: 'Carousel',
  byo_creative: 'Upload your own Design',
  conditional_product_groups: 'Conditional Product Groups',
  conditional_product_offer: 'Offer Eligibility',
  conditional_product_trial: 'Trial Eligibility',
  conditional_product_intro: 'Intro Eligibility',
  custom_font: 'Upload your own Brand Fonts',
  custom_launch_context: 'Launch Context',
  dark_mode: 'Dark Mode',
  dynamic_signin: 'Show/Hide Sign in Button',
  honest_paywall: 'Honest Paywall',
  multipage: 'Multi-screen Paywall',
  offer_code: 'Offer Codes',
  personalization: 'Personalization Tokens',
  product_groups: 'Product Groups',
  table: 'Table Component',
  toggle_product_offer: 'Offer Eligibility',
  toggle_product_trial: 'Trial Eligibility',
  upload_list_v2_icons: 'Upload your own List Icons',
  upload_list_v2_images: 'Upload your own List Images',
  video: 'Video',
  featured_product: 'Featured Product Styling',
  selected_product: 'Select to Purchase Product Styling',
  google_fonts: 'Add Google Fonts',
  conditionals: 'Conditional Display Logic',
  deeplink: 'Add a Deeplink Button',
  custom_launch_object: 'Custom Object',
  flow: 'Multi-screen Paywall Flow',
};

export type TTemplateCapabilityTag =
  | 'product_display'
  | 'design'
  | 'components'
  | 'dynamic_data'
  | 'offers';

export const TTemplateCapabilityTagReadable: Record<
  TTemplateCapabilityTag,
  string
> = {
  components: 'Components',
  design: 'Design',
  product_display: 'Product Display',
  offers: 'Offers',
  dynamic_data: 'Dynamic Data',
};

export type TAvailableCapability = {
  title: string;
  avatar: JSX.Element;
  capability: TTemplateCapability;
  description: string;
  ungatedInactiveActions: JSX.Element[];
  gatedInactiveActions: JSX.Element[];
  activeActions: JSX.Element[];
  entitlement: string;
  formFactors: TDevice[];
  noAdd?: boolean;
  enterpriseOnly?: boolean;
  tags: TTemplateCapabilityTag[];
};

export type TPaywallTemplate = {
  id: string;
  org_id: string | null;
  codename: string;
  doc_link: string;
  version: string;
  min_sdk_version?: string;
  'ui.maxSkus'?: number;
  'ui.requiredGroups'?: number;
  'ui.displaySingleGroup'?: boolean;
  'ui.dynamicAddGroups'?: boolean;
  initialState: {
    slides?: TCarouselSlidesState;
    currentPage: string;
    fullScreenPresentation?: boolean;
    isLoggedIn?: boolean;
    openHeaderIds?: string[];
    [key: string]: any;
  };
  variables?: { [key: string]: any };
  'ui.carousels': TCarouselSettings | null;
  'ui.formGroups'?: TFormGroup[];
  'ui.v2Ready'?: boolean;
  'ui.hasFeaturedFlag'?: boolean;
  'ui.hasSelectedFlag'?: boolean;
  'ui.productSettings'?: {
    variables: { [key: string]: any };
    variablesList: Array<TProductVariable>;
    fields: TFormSection[];
  };
  'ui.formFactors'?: TTemplateFormFactor[];
  'ui.capabilities'?: TTemplateCapability[];
  'ui.launchCustomAttributes'?: {
    [variableName: string]: string;
  };
  'ui.customObjectSchema': { [key: string]: any }; //TODO-temporarily stored here
  pages: TPaywallPage[];
};

export type TRawPaywallTemplate = TPaywallTemplate & {
  ready: boolean;
  thumbnail: string;
};

export type TTemplateMetadata = {
  groupIcons: { [key: string]: TIconName };
  orderedGroups: string[];
};

export type TTemplateWarning = {
  message: string;
  menuItem: string | null;
  editingComponentId: string | null;
  conditionsTab?: boolean;
  groupId?: string;
};

export type TIdLocations = { [id: string]: string };
export type TIdTypeLocation = { [id: string]: string[] };

export type TPaywallIdMeta = {
  idLocations: TIdLocations;
  idLocationsByType: TIdTypeLocation;
};
